import type { DcChatbot, ReqTypes } from "docuchatcommontypes";
import { DcChatbotStatus, DcEndPoints, QuestionSuggestionMethod } from "docuchatcommontypes";

export function useChatbot() {
  const t = useNuxtApp().$i18n.t;
  const toast = useToast();

  const { canWhiteLabel } = useLimits();
  // TODO: Use refs
  const user = useUserStore();
  const document = useDocumentStore();
  const chatbot = useChatbotStore();

  // CRUD operations
  async function createChatbot(params: ReqTypes.CreateChatBotRequest, updateCache = false) {
    const endpoint = DcEndPoints.CreateChatBot(params);
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }

    if (updateCache)
      await Promise.all([chatbot.fetchChatbots(), document.fetchDocuments(), user.fetchAccountUsages()]);

    return response.content.chatbotId;
  }

  async function editChatbot(params: ReqTypes.EditChatBotRequest, updateCache = false) {
    const endpoint = DcEndPoints.EditChatBot(params);
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }

    if (updateCache)
      await Promise.all([chatbot.fetchChatbots(), document.fetchDocuments(), user.fetchAccountUsages()]);

    return response.content.afterEditChatBot;
  }

  async function deleteChatbot(chatbotId: string, updateCache = false) {
    const endpoint = DcEndPoints.DeleteChatBot({ chatbotId });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }

    if (updateCache)
      await Promise.all([chatbot.fetchChatbots(), user.fetchAccountUsages()]);
  }

  async function addOrRemoveFavorite(chatbotId: string, isFavorite: boolean) {
    const endpoint = DcEndPoints.ChatBotFavorite({ chatbotId, isFavorite });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }

    await chatbot.fetchChatbots();
  }

  async function fetchChatbotStatuses(chatbotIds: string[]) {
    const endpoint = DcEndPoints.GetChatBotStatusAndId({ chatbotIds });
    const response = await useApi(endpoint);

    if (!response.success)
      return [];

    return response.content.idAndStatuses;
  }

  // Feedback
  async function sendFeedback(messageId: string, feedback: boolean, comment?: string, isNotCorrect?: boolean, isNotHelpful?: boolean, hasInconsistentLanguage?: boolean) {
    const endpoint = DcEndPoints.RateAnswer({
      messageId,
      ratedUp: feedback,
      comments: comment,
      isNotTrue: isNotCorrect,
      isNotHelpful,
      hasInconsistentLanguage,
    });
    await useApi(endpoint);

    // We do not check response.success because they are logged in the backend
  }

  // External chat methods
  async function initForChat(chatbotId: string) {
    const endpoint = DcEndPoints.InitChatBot({ chatbotId });
    const response = await useApi(endpoint);

    if (response.success)
      return response.content.sessionId;
    else
      throw createError(response.message ?? t("common.genericError"));
  }

  async function fetchForChat(chatbotId: string) {
    const endpoint = DcEndPoints.GetChatBotForChat({ chatbotId });
    const response = await useApi(endpoint);

    if (response.success)
      return response.content.chatbot;
    else
      throw createError(response.message ?? t("common.genericError"));
  }

  async function fetchForEmbed(chatbotId: string) {
    const endpoint = DcEndPoints.GetChatBotForEmbed({ chatbotId });
    const response = await useApi(endpoint);

    if (response.success)
      return response.content.chatbot;
    else
      throw createError(response.message ?? t("common.genericError"));
  }

  /**
   * Validates if the chatbot can be used for chat. It also shows a notification based on the chatbot status.
   * @param chatbotId - Chatbot id
   * @param chatbotStatus - Chatbot status
   * @param ignoreForNotifications - Statuses to ignore for showing notifications
   */
  function validateCanChat(chatbotId: string, chatbotStatus: DcChatbotStatus, ignoreForNotifications: DcChatbotStatus[] = []) {
    const toast = useToast();

    const bot = chatbot.getChatbotFromCacheById(chatbotId);
    const name = bot ? bot.name : "Your chatbot";
    const sendNotification = !ignoreForNotifications.includes(chatbotStatus);

    switch (chatbotStatus) {
      case DcChatbotStatus.Draft:
        if (sendNotification) {
          toast.add({
            title: t("user.chatbots.toasts.draft.title", { name }),
            description: t("user.chatbots.toasts.draft.description"),
            icon: "i-carbon-license-draft",
            color: "gray",
          });
        }
        return false;
      case DcChatbotStatus.Initialized:
      case DcChatbotStatus.Queued:
      case DcChatbotStatus.Processing:
        if (sendNotification) {
          toast.add({
            title: t("user.chatbots.toasts.notReady.title", { name }),
            description: t("user.chatbots.toasts.notReady.description"),
            icon: "i-carbon-in-progress",
            color: "gray",
          });
        }
        return false;
      case DcChatbotStatus.SomeDocumentsFailed:
        if (sendNotification) {
          toast.add({
            title: t("user.chatbots.toasts.someFailed.title", { name }),
            description: t("user.chatbots.toasts.someFailed.description"),
            icon: "i-carbon-in-progress-warning",
            color: "amber",
          });
        }
        return true;
      case DcChatbotStatus.Failed:
        if (sendNotification) {
          toast.add({
            title: t("user.chatbots.toasts.failed.title", { name }),
            description: t("user.chatbots.toasts.failed.description"),
            icon: "i-carbon-in-progress-error",
            color: "red",
          });
        }
        return false;
      case DcChatbotStatus.Ready:
        if (sendNotification) {
          toast.add({
            title: t("user.chatbots.toasts.ready.title", { name }),
            description: t("user.chatbots.toasts.ready.description"),
            icon: "i-carbon-checkmark-filled",
          });
        }
        return true;
    }
  }

  function validateChatbot(chatbot: DcChatbot) {
    if (chatbot.whiteLabel.enabled && !canWhiteLabel.value) {
      toast.add({
        title: t("user.chatbots.toasts.cannotBeWhiteLabelled.title"),
        description: t("user.chatbots.toasts.cannotBeWhiteLabelled.description"),
        icon: "i-carbon-color-palette",
        color: "amber",
      });
    }

    if (chatbot.answers.questionSuggestionMethod === QuestionSuggestionMethod.AutoGenerateFailed) {
      toast.add({
        title: t("user.chatbots.toasts.autoGenFailed.title"),
        description: t("user.chatbots.toasts.autoGenFailed.description"),
        icon: "i-carbon-magic-wand-filled",
        color: "amber",
      });
    }
  }

  return {
    createChatbot,
    editChatbot,
    deleteChatbot,
    addOrRemoveFavorite,
    fetchChatbotStatuses,

    initForChat,
    fetchForChat,
    fetchForEmbed,
    sendFeedback,

    validateCanChat,
    validateChatbot,
  };
}
